<div class="container">
    <h1 class="my-4">Return / Refund / Cancellation Policy</h1>
<h2>ResilienceSoft IT Company</h2>

<p>This policy applies to all <b>service-based engagements</b>  with international clients. ResilienceSoft does <b>not sell physical products</b>, and all terms outlined here relate exclusively to <b> IT services, software development, and consulting.</b></p>
<h3>1. Refundable or Cancellable Services</h3>
<ul>
    <li>As a <b>services-only company</b>, our offerings are <b>eligible for cancellation and refund</b>  under certain conditions, outlined below.</li>
    <li>Physical product return policies do not apply, as no physical goods are sold or delivered.</li>
    <li>Only <b>service fees</b>  are eligible for refunds if cancellation is approved as per the terms below.</li>
  </ul>
<h3>2. Conditions, Process & Timelines for Refunds/Cancellations</h3>
<p><b>Eligibility Conditions:</b></p>
<ul>
    <li>Cancellation is allowed <b>before</b>  or <b>during the execution of a service</b>.</li>
    <li>Refund eligibility is based on the stage of the service delivery:</li>
   <ul>
    <li><b>Before project initiation:</b> 90% refund (10% admin fee deducted).</li>
    <li><b>Up to 25% project completion:</b> 50% refund.</li>
    <li><b>25% to 75% completion:</b> 25% refund.</li>
    <li><b>Over 75% completion:</b> No refund available.</li>
   </ul>
</ul>
<p> <b>How to Initiate:</b></p>
<ul>
    <li>Email your request to <b>{{mail}}</b>.</li>
    <li>Include:</li>
    <ul>
        <li>Subject: <b>“Refund/Cancellation Request – [Your Project Name]”</b></li>
        <li>Invoice number and payment proof</li>
        <li>Reason for cancellation</li>
    </ul>
    <li>We will review and confirm the status within <b> 3 business days</b>.</li>
    <p class="mt-2"><b>Timeline for Processing:</b></p>
    <li>Once approved, refunds will be processed within <b>7 to 15 business days</b>.</li>
    <li>Refunds will be issued via the <b> original payment method</b> (e.g., bank transfer, PayPal).</li>
    <li>Any third-party charges (e.g., bank/transfer fees, currency conversion loss) are <b> non-refundable</b>.</li>
</ul>
<h3>3. Shipping / Delivery Policy</h3>
<ul>
    <li> <b>No physical shipping</b> is involved, as ResilienceSoft provides only <b>digital/remote services.</b> </li>
    <li>Service deliverables (e.g., source code, documentation, reports) are delivered via:</li>
    <ul>
        <li>Email</li>
        <li>Cloud storage (e.g., Google Drive, Dropbox, OneDrive)</li>
        <li>Project management tools or client’s preferred platform</li>
    </ul>
    <li>Timelines for delivery will be defined in the project agreement or service-level contract.</li>
</ul>
<p>For any further assistance, please contact:</p>
📧 Email: <a href="mailto:info@resiliencesoft.com"> <b>{{mail}}</b> </a> 
</div>